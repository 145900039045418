import React from 'react'
import { Flex } from 'antd-mobile'
import PriceTag from '../PriceTag'
import RoleTag from '../RoleTag'
import styles from './normalGoods.module.less'
import { useSelector } from 'react-redux'

export default function GoodsUserPrice({
  data,
  className,
  direction = 'column',
  isHotDiscount,
  isPrice,
  isTag = true,
  isRebuy = true
}) {
  const user = useSelector(state => state.user)
  const role = user.data._userIdentity
  const hasRole = role.vip && role.vipStatus <= 1

  const price = data.rank_price
  //活动加不为0  显示两个价格
  const isTwoPrice = data.activity_price * 1
  //带身份的价格
  const rolePrice = () => (
    <Flex direction={direction} align="start" className={className}>
      <Flex justify="center" className={styles.mb10}>
        <PriceTag value={price} />
        {!(
          data.is_flash_sale * 1 ||
          data.is_seckill * 1 ||
          data.is_newborn_zone * 1
        ) &&
          isRebuy &&
          isTag && (
            // !isPrice &&
            <RoleTag isRebuy={data.is_rebuy} data={data} />
          )}
      </Flex>
      {!isPrice && (
        <Flex justify="center">
          {data.product_price && !data.is_newborn_zone && isRebuy ? (
            <PriceTag type="light" value={data.product_price} />
          ) : (
            ''
          )}
          <PriceTag type="dash" value={data.original_price} />
        </Flex>
      )}
    </Flex>
  )
  //普通用户价格
  const ordinary = () => (
    <Flex
      direction={direction}
      align="start"
      justify="between"
      className={styles.mb5}
    >
      <PriceTag value={data.product_price} />
      {!isPrice && <PriceTag type="dash" value={data.original_price} />}
    </Flex>
  )
  //活动显示价格(两个价格)
  const twoPrice = () => (
    <Flex
      direction={direction}
      align="start"
      justify="between"
      className={styles.mb5}
    >
      <PriceTag value={data.activity_price} />
      {!isPrice && (
        <PriceTag
          type="dash"
          value={hasRole ? data.product_price : data.original_price}
        />
      )}
    </Flex>
  )

  const hotPrice = () => (
    <Flex
      direction={direction}
      align="end"
      justify="between"
      className={styles.mb8}
    >
      <Flex align={direction === 'row' ? 'end' : 'center'} justify="between">
        <PriceTag
          className={styles.activityPrice}
          value={data.activity_price}
        />
        <span className={styles.activityTxt}>{data.activity_price_text}</span>
      </Flex>
      {!isPrice && <PriceTag type="dash" value={data.sku_price} />}
    </Flex>
  )

  return isHotDiscount
    ? hotPrice()
    : isTwoPrice
    ? twoPrice()
    : !hasRole
    ? ordinary()
    : rolePrice()
}
