import React from 'react'
import { Flex } from '@/components'
import { getImageUrl } from '@/utils/utils'
import styles from './styles.module.less'
import { useSelector } from 'react-redux'
import rebuy from './rebuy.svg'

// 获取身份text
// isRebuyDiscount: 复购价样式(详情页)
export const getUserIdentityImg = (info, isRebuy, data, isRebuyDiscount) => {
  const target =
    data &&
    data.activity_list &&
    data.activity_list.find(
      item => item.type === 'tbk_coupon' && +item.after_price
    )
  if (
    target ||
    info.superPartner ||
    info.highPartner ||
    info.partner ||
    info.vip
  )
    return isRebuyDiscount ? 'rebuy' : rebuy
  if (data && +data.is_tbk) return
  if (info.vip && info.vipStatus <= 1 && isRebuy)
    return isRebuyDiscount ? 'rebuy' : rebuy
  if (!info) return null
  return null
}

// isRebuyDiscount: 复购价样式(详情页)
export default function RoleTag({ isRebuy, isRebuyDiscount, data }) {
  const user = useSelector(state => state.user)
  const info = user.data._userIdentity
  const img = getUserIdentityImg(info, isRebuy * 1, data, isRebuyDiscount)
  if (!img) return null
  if (img === 'rebuy') {
    // 复购
    return data.is_straight_down !== 1 ? (
      <Flex align="end">
        <span className={styles.primary}>复购价</span>
        {+data.rebuy_discount !== 1 ? (
          <Flex
            align="center"
            justify="center"
            className={styles.tagBox}
            style={{
              background: `url(${getImageUrl('repurchaseBg1.png')})`,
              backgroundSize: 'cover'
            }}
          >
            <span size="sm" className="-i-text-white">
              复购享
            </span>
            <span className="-i-text-white">
              {data ? Number((data.rebuy_discount * 10).toFixed(1)) : 0}
            </span>
            <span size="sm" className="-i-text-white">
              折
            </span>
          </Flex>
        ) : null}
      </Flex>
    ) : null
  }
  return <img className={styles.tag} src={img} alt="" />
}
