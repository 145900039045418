import React from 'react'
import { Flex } from 'antd-mobile'
import PriceTag from '../PriceTag'
import styles from './index.module.less'
import { useSelector } from 'react-redux'

/** 商品价格显示(分类和详情页) */
export default function NewGoodsPrice({
  data,
  className,
  userInfo,
  direction = 'column',
  isPrice,
  afterClassName,
  addonAfter
}) {
  const user = userInfo || useSelector(state => state.user.data)
  const role = user._userIdentity
  const hasRole = role.vip && role.vipStatus <= 1
  return (
    <>
      {+data.activity_price === 0 ? (
        <Flex
          className={`${styles.newGoodsPrice} ${className}`}
          direction={direction}
        >
          {hasRole ? (
            <Flex direction={direction} align="end">
              <Flex>
                <PriceTag
                  color="#FF5813"
                  className={styles.m0}
                  value={data.rank_price}
                  addonAfter={addonAfter}
                />
              </Flex>
              {!isPrice && (
                <Flex
                  align="center"
                  className={`${styles.tag} ${afterClassName}`}
                >
                  <PriceTag
                    size="sm"
                    className={`${styles.m0} ${styles.small}`}
                    value={data.product_price}
                    color="#FF753C"
                  />

                  <span className={styles.smallTag}>日常价</span>
                </Flex>
              )}
            </Flex>
          ) : (
            <Flex direction={direction}>
              <PriceTag
                color="#FF5813"
                className={styles.m0}
                value={data.product_price}
                addonAfter={addonAfter}
              />
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex align="end" className={`${styles.newGoodsPrice}`}>
          <Flex className="mr-20px">
            <PriceTag
              className={styles.m0}
              color="#FF5813"
              value={data.activity_price}
              addonAfter={addonAfter}
            />
          </Flex>

          {!isPrice && (
            <Flex align="center" className={`${styles.tag} ${afterClassName}`}>
              <PriceTag
                size="small"
                className={`${styles.m0} ${styles.small}`}
                value={data.product_price}
                color="#FF753C"
              />
              <span className={styles.smallTag}>日常价</span>
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}
