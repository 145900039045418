import React, { useEffect, useState } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { flattenRoutes } from '@/routes'
import { Icon } from '@/components'
import styles from './styles.module.less'
import { isAdminView, staticPath } from '@/variable'
import { getBottomBarDesign } from '@/services/global'
import { Toast } from 'antd-mobile'
import Badge from '@/components/Badge'
import MobileAuthWrapper from '../GlobalMobileAuth/MobileAuthWrapper'

const navBarConfig = [
  {
    icon: 'icon-a-shangxiang_icon_kefu1',
    path: '/',
    name: '首页',
    exact: true,
    code: '1001'
  },
  {
    icon: 'icon-fenlei1',
    path: '/category',
    exact: true,
    name: '分类',
    code: '1006'
  },
  // {
  //   icon: 'icon-sucaiquan',
  //   path: '/discover',
  //   name: '素材圈',
  //   bind: true,
  //   code: '1003'
  // },
  {
    icon: 'icon-gowuche',
    path: '/cart',
    name: '购物车',
    code: '1004'
  },
  {
    icon: 'icon-wode',
    path: '/mine',
    name: '我的',
    code: '1005'
  }
]

const NavLinkComp = ({ props }) => {
  return (
    <MobileAuthWrapper>
      {({ needBind, bindRoute }) => (
        <li
          key={props.name}
          style={{ position: 'relative' }}
          onClick={() => {
            window.JAnalyticsCevent('12.12-shouyedaohang', 'tab', props.name)
          }}
        >
          {props.default_color ? (
            <NavLink
              activeClassName={styles.ac}
              className={`${styles.par} ${styles.nav}`}
              style={{ color: `${props.default_color}` }}
              activeStyle={{ color: `${props.change_color}` }}
              to={needBind && props.bind ? bindRoute : props.path}
              exact={props.exact}
            >
              <div className={styles.iconbody}>
                <img
                  src={`${staticPath}${props.default_icon}`}
                  alt="icon"
                  className={`${styles.icon} ${styles.iconImg}`}
                />
                <img
                  src={`${staticPath}${props.change_icon}`}
                  alt="icon"
                  className={`${styles.fill} ${styles.iconImg}`}
                />
              </div>
              <span className={`${styles.name} ${styles.changeName}`}>
                {props.name}
              </span>
            </NavLink>
          ) : (
            <NavLink
              activeClassName={styles.ac}
              className={styles.par}
              to={needBind && props.bind ? bindRoute : props.path}
              exact={props.exact}
            >
              <Icon className={styles.iconMain} icon={props.icon} />
              <span className={styles.name}>{props.name}</span>
            </NavLink>
          )}
          {props.icon === 'icon-gowuche' ? <Badge /> : null}
        </li>
      )}
    </MobileAuthWrapper>
  )
}

export default withRouter(({ location }) => {
  const [nav, setNav] = useState([])
  const pathname = location.pathname
  const showNavbar = flattenRoutes.find(
    el => el.path === pathname && el.showNavbar
  )
  useEffect(() => {
    const fetch = async () => {
      const { data, type, msg } = await getBottomBarDesign()
      if (type === 1) return Toast.fail(msg)
      const newIcon = ArrayMap(navBarConfig, data)
      setNav(newIcon)
    }
    fetch()
  }, [])

  if (isAdminView) return null
  return (
    <>
      {showNavbar && <div className="has_bottom_bar" />}
      <div
        className={styles.mainNavWrapper}
        style={{ display: showNavbar ? 'block' : 'none' }}
      >
        <ul className={styles.mainNav}>
          {nav.map(r => {
            return <NavLinkComp key={r.name} props={r} />
          })}
        </ul>
      </div>
    </>
  )
})

function ArrayMap(arr1, arr2) {
  if (!arr2.length) return arr1
  const newTab = arr1
  arr1.forEach((el, ed) => {
    arr2.forEach((int, ind) => {
      if (el.code * 1 === int.code * 1) {
        // newTab.push({ ...el, ...int })
        newTab.splice(ed, 1, { ...el, ...int })
      }
    })
  })
  return newTab
}
