import React from 'react'
import styles from './styles.module.less'

const PriceComp = ({
  value = 0,
  wrapperClass = 'price',
  symbol,
  size = '',
  color,
  style = {},
  className,
  addonAfter = '',
  ...props
}) => {
  let str = value
  if (typeof value !== 'number' && typeof value !== 'string') return 0
  if (typeof value === 'number') str += ''
  const [i, f] = String(str * 1).split('.')
  const sys = f * 1 > 0 ? '.' : ''
  return (
    <span
      className={`${className} ${styles[wrapperClass]} ${
        size ? styles[size] : ''
      }`}
      style={{ ...style, color }}
      {...props}
    >
      {wrapperClass === 'nprice' && (
        <i className={styles.throughLine} style={{ color }} />
      )}
      <small>{symbol}</small>
      <span className={`${styles.int} int`}>{i}</span>
      {sys}
      <small>
        {sys === '.' && f ? (f.length === 1 ? `${f}0` : f) : ''}
        {addonAfter}
      </small>
    </span>
  )
}

export default function PriceTag({
  value,
  size,
  type = 'primary',
  symbol = '¥',
  addonAfter = '',
  ...props
}) {
  if (type === 'primary') {
    return (
      <PriceComp
        size={size}
        wrapperClass="price"
        value={value}
        symbol={symbol}
        addonAfter={addonAfter}
        {...props}
      />
    )
  } else if (type === 'light') {
    return (
      <PriceComp
        size={size}
        wrapperClass="sprice"
        value={value}
        symbol={symbol}
        addonAfter={addonAfter}
        {...props}
      />
    )
  } else {
    return (
      <PriceComp
        wrapperClass="nprice"
        value={value}
        symbol={symbol}
        addonAfter={addonAfter}
        {...props}
      />
    )
  }
}
